import AntroVectraOTF from "./AntroVectra-Bolder.otf";
import QuinzeyOTF from "./Quinzey-Bold.otf";
import FiraCodeOTF from "./FiraCode-Regular.otf";
import FiraCodeWOFF2 from "./FiraCode-Regular.woff2";
import FiraCodeLightOTF from "./FiraCode-Light.otf";
import FiraCodeLightWOFF2 from "./FiraCode-Light.woff2";

export default {
	AntroVectraOTF,
	QuinzeyOTF,
	FiraCodeOTF,
	FiraCodeWOFF2,
	FiraCodeLightOTF,
	FiraCodeLightWOFF2
};