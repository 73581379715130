import { injectGlobal } from "styled-components";
import { media } from "../utils/mediaSizr";
import * as colors from "../utils/colors";
import fonts from "./fonts/fonts";

export default injectGlobal`

	font-size: 16px;
	/*
	TODO: Fix sizes with below
	:root {
		${media.desktop`
			font-size: 16px;
		`}
		${media.tablet`
			font-size: 15px;
		`}
		${media.mobile`
			font-size: 14px;
	`}
	}
	*/

	@font-face {
		font-family: "Antro Vectra";
		font-display: auto;
		src: local("Antro Vectra"), url(${fonts.AntroVectraOTF}) format("opentype");
	}

	@font-face {
		font-family: "Quinzey";
		font-display: auto;
		src: local("Quinzey"), url(${fonts.QuinzeyOTF}) format("opentype");
	}

	@font-face{
		font-family: "Fira Code";
		font-display: auto;
		src: local("Fira Code"), url(${fonts.FiraCodeOTF}) format("opentype"),
			url(${fonts.FiraCodeWOFF2}) format('woff2');
		}
	}

	@font-face{
		font-family: "Fira Code Light";
		font-display: auto;
		src: local("Fira Code Light"), url(${fonts.FiraCodeLightOTF}) format("opentype"),
			url(${fonts.FiraCodeLightWOFF2}) format('woff2');
		}
	}

	body {
	font-size: 1.1em;
	font-family: "Dosis", Helvetica, Arial, sans-serif;
	background: linear-gradient(${colors.ui.alpha(.1)}, ${colors.ui.alpha(.01)}) fixed;
	font-weight: 300;
	margin: 0;
	padding: 0;
	display: flex;
	flex-direction: column;
	}

	/* Semantic UI overrides */
	.ui * {
		font-family: "Dosis", Helvetica, Arial, sans-serif;
	}
	.ui.segment, .ui.segments {
		border-radius: 0 !important;
		font-size: 1em !important;

		border: 0;

	}
	.ui.raised.card {
		box-shadow: .1rem .1rem 2rem 0 ${colors.text.alpha(.2)};
	}
	.ui.card {
		border-radius: 0 !important;
		font-size: 1em !important;
		box-shadow: 0 .1rem .3rem 0 ${colors.text.alpha(.2)};
		.meta {
			font-size: .9rem;
		}
		img {
			height: 10rem;
			object-fit: cover;
			${media.desktop`
			height: 13rem;
			`}
		}

		>:first-child {
			border-radius: 0 !important;
		}
	}
	.ui.vertical.menu {
		border: none !important;
		border-radius: 0 !important;
		color: ${colors.text.alpha(.6)} !important;
	}
	.ui.vertical.menu .item {
			color: ${colors.text.solid} !important;
	}
	.ui.vertical.menu .active.item {
			background: linear-gradient(${colors.ui.alpha(.05)}, ${colors.ui.alpha(.05)}),
		linear-gradient(45deg, ${colors.ui.alpha(.1)} 70%, ${colors.ui.alpha(.2)} 50%);
			border-radius: 0 !important;
			color: ${colors.text.solid} !important;
	}
`;