import React, { Component } from "react";
import styled from "styled-components";
import { Icon } from "semantic-ui-react";
import * as colors from "../utils/colors";

/* Styles */

const FooterWrapper = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: row;
	height: 3rem;
	background-image: linear-gradient(${colors.ui.alpha(.05)}, ${colors.ui.alpha(.05)}),
		linear-gradient(45deg, ${colors.ui.alpha(.2)} 80%, ${colors.ui.alpha(.1)} 50%);
`;

const StyleHref = styled.a`
	margin: 0 1rem 0 1rem;
	color: ${colors.text.alpha(.6)};
	text-justify: center;
	font-size: 1.1rem;
	transition: color 0.3s ease-in-out;
	:hover {
		color: ${colors.ui.solid};
		text-decoration:none;
		cursor:pointer;
	}
`;

const socialServices = [
	{ name: "linkedin", url: "https://www.linkedin.com/in/kenniy"},
	{ name: "github", url: "https://github.com/kenniy"},
	{ name: "bitbucket", url: "https://bitbucket.org/kenniy/"},
	{ name: "envelope outline", url: "mailto:kenniy@olorunnimbe.com" },
	{ name: "twitter", url: "https://twitter.com/kenniy"}
];

/* Component */

class LayoutFooter extends Component {
	render () {
		return (
			<FooterWrapper>
				{
					socialServices.map((service) => {
						return (
							<StyleHref color={service.color}
								href={service.url}
								rel="noopener"
								key={service.name}
								title={service.name}
								target="_blank">
								<Icon fitted
									name={service.name}
									size="large"
								/>
							</StyleHref>
						);})
				}
			</FooterWrapper>
		);
	}
}

export default LayoutFooter;