import { css } from "styled-components";

//*********************************************************
// Sizes
//*********************************************************
export const sizes = {
	desktop: 7680,
	tablet: 1024,
	mobile: 650,
	tiny: 360
};

export const mapSizesToProps = ({ width }) => ({
	isDesktop: width > sizes.tablet,
	isTablet: width > sizes.mobile && width <= sizes.tablet,
	isMobile: width <= sizes.mobile,
	isTiny: width <= sizes.tiny //for when i need to classify tiny mobile
});

// via https://github.com/styled-components/styled-components/blob/master/docs/tips-and-tricks.md
// iterate through the sizes and create a media template
export const media = Object.keys(sizes).reduce((accumulator, label) => {

	// use em in breakpoints to work properly cross-browser and support users
	// changing their browsers font-size: https://zellwk.com/blog/media-query-units/
	const remSize = `${sizes[label] / 16}rem`;

	accumulator[label] = (...args) => css`
		@media (max-width: ${remSize}) {
			${css(...args)}
		}
	`;

	return accumulator;
}, {});