import React from "react";
import { navigate } from "gatsby";
import PropTypes from "prop-types";
import styled from "styled-components";
import { Link } from "gatsby";
import { Header, Icon, Menu, TransitionablePortal } from "semantic-ui-react";
import withSizes from "react-sizes";
import logoSVG from "../assets/images/logo.svg";
import { media, mapSizesToProps } from "../utils/mediaSizr";
import * as colors from "../utils/colors";

/* Styles */

const HeaderWrapper = styled.div`
	display: flex;
	flex-direction: column;
	background: linear-gradient(${colors.ui.alpha(.05)}, ${colors.ui.alpha(.05)}),
		linear-gradient(45deg, ${colors.ui.alpha(.1)} 75%, ${colors.ui.alpha(.2)} 50%);
	height: 10rem;
	padding: 1rem 5rem 1rem 5rem;
	justify-content: space-between;
	/* box-shadow: 0 1px 1px 0 rgba(159, 126, 92, 0.2); */
	${media.tablet`
		padding: 1rem 2rem 0.6rem 2rem;
		height: 9rem;
	`}
	${media.mobile`
		padding: .6rem .6rem .2rem .6rem;
		height: 7rem;
	`}
`;

const LogoContainer = styled.div`
	img {
		height: 3rem;
	}

	${media.mobile`
		img {
			height: 2.5rem;
		}
	`}

	a {
		opacity: .9;
		transition: opacity 0.3s ease-in-out;
		cursor: pointer;
		:hover {
			opacity: 1;
		}
	}
	a[aria-current] {
		opacity: 1;
	}
`;

const TitleContainer = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: stretch;

	.ui.header {
		${media.desktop`
		font-size: 4rem;
		`}
		${media.tablet`
			font-size: 3rem;
		`}
		${media.mobile`
			font-size: ${(props) => props.isPost ? "2rem" : "2.5rem"};
		`}
		${media.tiny`
			font-size: ${(props) => props.isPost ? "1.7em" : "2.3rem"};
		`}
		font-family: ${(props) => props.isMain ? "Antro Vectra" : "Quinzey"} !important;
		text-shadow: ${(props) => props.isMain ? "0px .5px, .5px 0px, .5px .5px" : "none"} !important;
		color: ${colors.text.solid};

		.sub.header {
			color: ${(props) => props.isMain ? `${colors.ui.solid}` : ""} !important;
			text-shadow: none;
			font-family: "Dosis", Helvetica, Arial, sans-serif; //TODO: to cleanup
			margin-top: ${(props) => props.isMain ? "-2rem" : "-1.3rem"};
			${media.tablet`
				margin-top: ${(props) => props.isMain ? "-1.6rem" : "-.9rem"};
			`}
			${media.mobile`
				margin-top: ${(props) => props.isMain ? "-1.3rem" : "-.6rem"};
				font-size: ${(props) => props.isPost ? ".8rem" : "1rem"} !important;
			`}
		}
	}
`;

const MenuWrapper = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: space-between;
`;

const FullMenuStyle = `
	.icon {
		display: none;
	}
		display: flex;
		flex-direction: row;
		height: 3rem;
		justify-content: space-between;

	.menu .item {
		color: ${colors.text.alpha(.6)} !important;
		:hover {
			color: ${colors.text.solid} !important;
		}
	}

	.menu .active.item {
		font-weight: inherit !important;
		color: ${colors.text.solid} !important;
	}
`;

const MiniMenuStyle = `
	.menu {
		display: none;
	}
	.icon {
		display: block;
		width: 2.5rem;
		font-size: 1.3rem;
		color: ${colors.ui.solid};
		padding: .5rem 0;
	}
`;

const MenuContainer = styled.div`
	${media.desktop`
		${FullMenuStyle}
	`}
	${media.tablet`
		${MiniMenuStyle}
	`}
	${media.mobile`
		${MiniMenuStyle}
	`}
`;

const menuItems = [
	"blog",
	"publications"
];

/* Component */
class LayoutHeader extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			open: false
		};
	}
	handleClick = () => this.setState({open: !this.state.open});
	handleClose = () => this.setState({open: false});
	handleMenuItemClick = (e, { name }) => {
		this.setState({
			open: !this.state.open
		});
		navigate(`/${name}`);
	}

	renderMenuItems = () => {
		const title = this.props.title ? this.props.title.toLowerCase() : "";

		return menuItems.map((menuItem) => (
			<Menu.Item
				key={menuItem}
				name={menuItem}
				active={menuItems.includes(title) && title === menuItem}
				onClick={this.handleMenuItemClick}
			/>
		));
	}

	renderMenu = () => {
		return (
			<MenuContainer>
				{this.renderFullMenu()}
				{this.renderMiniMenuIcon()}
				{!this.props.isDesktop && this.miniMenuTransition()}
			</MenuContainer>
		);
	};

	renderFullMenu = () => (
		<Menu pointing secondary
			position="right"
			style={{margin: 0}}
		>
			<Menu.Menu position="right">
				{this.renderMenuItems()}
			</Menu.Menu>
		</Menu>
	);

	renderMiniMenuIcon = () => (<Icon
		name="ellipsis vertical"
		onClick={this.handleClick}
	/>
	);

	miniMenuTransition = () => (
		<TransitionablePortal
			onClose={this.handleClose}
			open={this.state.open}
			transition={{animation: "fade left", duration: 200 }}
		>
			<Menu vertical
				size="huge"
				style={{ right: "2%", position: "fixed", top: "-1%", zIndex: 10 }}
			>
				{this.renderMenuItems()}
			</Menu>
		</TransitionablePortal>)

	render () {
		let {title, date} = this.props;
		let isPost = date !== undefined;
		let isMain;
		if (!title) {
			title = "Kenniy Olorunnimbe";
			isMain = true;
		}
		return (
			<HeaderWrapper>
				<MenuWrapper>
					<LogoContainer>
						<Link to="/">
							<img
								src={logoSVG}
								alt="logo"
							/>
						</Link>
					</LogoContainer>
					{this.renderMenu()}
				</MenuWrapper>

				<TitleContainer isMain={isMain} isPost={isPost}>
					<Header textAlign={isMain ? "right" : "left"}>
						{title}
						{(isPost || isMain) &&
							<Header.Subheader>
								{isPost ? date : "a data nerd..."}
							</Header.Subheader>}
					</Header>
				</TitleContainer>
			</HeaderWrapper>
		);
	}
}

LayoutHeader.propTypes = {
	title: PropTypes.string,
	date: PropTypes.string,
	isDesktop: PropTypes.bool
};
export default withSizes(mapSizesToProps)(LayoutHeader);