import React from "react";
import PropTypes from "prop-types";
import { Segment } from "semantic-ui-react";
import styled from "styled-components";
import { mapSizesToProps } from "../utils/mediaSizr";
import withSizes from "react-sizes";
import Layout from "../components/Layout";

const SummaryWrapper = styled(Segment)`
	white-space: pre-line;
`;

const summary =`I am a Software Developer at Klipfolio Inc. I (and team) am currently working on building a consolidated metrics hub for different services, using a combination of GraphQL + Apollo, React and Spring Boot. I am also part of the Insights team, as well as the Data Science guild, where we discuss and implement different ML/AI use cases for the business. Other guilds I attend are the UI, Testing and Platform guilds.

	Outside of work, I am a Ph.D. student in the Computer Science Department of the University of Ottawa. My primary research is exploring the amalgamation of a secure distributed transaction store and natural computation (such as artificial neural network, evolutionary algorithms, swarm intelligence, etc.).

	I am also really curious about Capsule Networks, especially how it compares with more "traditional" networks, such as Convolutional Neural Networks, so some work on that as well. I had previously worked on efficient ensemble algorithms in data streams in a concept drift scenario.

	In my previous life, I had worked in network & data administration.
	`;

const IndexPage = ({isMobile}) => (
	<Layout>
		<SummaryWrapper
			padded={isMobile ? false : true}>
			{summary}
		</SummaryWrapper>
	</Layout>

);

IndexPage.propTypes = {
	isMobile: PropTypes.bool
};
export default withSizes(mapSizesToProps)(IndexPage);
