const palette = {
	//courtesy https://www.w3schools.com/colors/colors_trends.asp
	meerkat: "rgb(169, 117, 79)",
	sargassoSea: "rgb(72, 81, 103)",
	ultraViolet: "rgb(107, 91, 149)",
	redPear: "rgb(127, 65, 69)",
	brilliantBlue: "rgb(48,23,251)",
};

const COLOR = {
	ui: palette.meerkat,
	text: "rgb(51, 49, 49)"
};

const withAlpha = (color, opacity) => color.replace("b", "ba").replace(")", `, ${opacity})`);

export const ui = {
	solid: COLOR.ui,
	alpha: (opacity) => withAlpha(COLOR.ui, opacity)
};

export const text = {
	solid: COLOR.text,
	alpha: (opacity) => withAlpha(COLOR.text, opacity)
};

const colors = {
	ui: ui,
	text: text
};

export default colors;