import React from "react";
import PropTypes from "prop-types";
import Helmet from "react-helmet";
import { StaticQuery, graphql } from "gatsby";
import "semantic-ui-css/semantic.min.css";
import "prismjs/themes/prism.css";
import "katex/dist/katex.min.css";
import styled from "styled-components";
import "../assets/globalStyles";
import LayoutHeader from "./LayoutHeader";
import LayoutFooter from "./LayoutFooter";
import { media } from "../utils/mediaSizr";
import * as colors from "../utils/colors";

const LayoutWrapper = styled.div`
	display: flex;
	flex-direction: column;
	color: ${colors.text.solid};
	min-height: 100vh;
	max-weight: 100%;
`;

export const ContentWrapper = styled.div`
	width: 100%;
	flex: 1;
	align-self: center;

	${media.desktop`
		padding: ${(props) => props.isPreviewContent ? "0 20vw 0 20vw" : "0 25vw 0 25vw"} !important;
		margin: 3vh 0 1vh 0;
	`}
	${media.tablet`
		padding: 0 10vw 0 10vw !important;
		margin: 3vh 0 3vh 0;
	`}
	${media.mobile`
		padding: ${(props) => props.isPreviewContent ? "0 3vw 0 3vw" : "0 1vw 0 1vw"} !important;
		margin: 2vh 0 2vh 0;
	`}
`;

const Layout = ({ children, title, date }) => {
	return (
		<StaticQuery
			query = { graphql`
			query {
				site {
					siteMetadata {
						title
					}
				}
			}
		`}
			render={(data) => (
				<LayoutWrapper>
					<Helmet
						defaultTitle={data.site.siteMetadata.title}
						titleTemplate={`%s | ${data.site.siteMetadata.title}`}>
						<meta name="twitter:site" content="@kenniy" />
						<meta name="og:site_name" content={data.site.siteMetadata.title} />
						<meta name="description" content={`Personal website for ${data.site.siteMetadata.title}`} />
						<html lang="en" />
					</Helmet>
					<LayoutHeader {...{title, date}}/>
					<ContentWrapper
						isPreviewContent={title && title.toLowerCase() === "blog"}>
						{children}
					</ContentWrapper>

					<LayoutFooter/>
				</LayoutWrapper>
			)}
		/>
	);
};

Layout.propTypes = {
	children: PropTypes.node.isRequired,
	title: PropTypes.string,
	date: PropTypes.string
};
export default Layout;
